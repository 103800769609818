export const strings = {
  SHUFFLE_TOOLTIP: 'Completely randomizes the order of programs.',
  ALPHA_SORT_TOOLTIP: 'Sorts alphabetically by program title',
  CYCLIC_SHUFFLE_TOOLTIP:
    'Like Random Shuffle, but tries to preserve the sequence of episodes for each TV show. If a TV show has multiple instances of its episodes, they are also cycled appropriately.',
  BLOCK_SHUFFLE_TOOLTIP:
    'Alternates TV shows in blocks of episodes. You can pick the number of episodes per show in each block and if the order of shows in each block should be randomized. Movies are moved to the bottom.',
  EPISODE_SORT_TOOLTIP:
    'Sorts the list by TV Show and the episodes in each TV show by their season/episode number. Movies are moved to the bottom of the schedule.',
  RELEASE_SORT_TOOLTIP:
    'Sorts everything by its release date. This will only work correctly if the release dates in Plex are correct. In case any item does not have a release date specified, it will be moved to the bottom.',
  FFMPEG_MISSING:
    'FFMPEG not found. For all features to work, we recommend installing FFMPEG 6.1+ or update your FFMPEG executable path in settings.',
};
